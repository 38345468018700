@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide-up {
	from {
		transform: translateY(100%);
	}

	to {
		transform: translateY(0);
	}
}

@keyframes slide-down {
	from {
		transform: translateY(0);
	}

	to {
		transform: translateY(100%);
	}
}

.animate-slide-up {
	animation: slide-up 0.2s ease-out forwards;
}

.animate-slide-down {
	animation: slide-down 0.2s ease-out forwards;
}